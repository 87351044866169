import React, { useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { Theme, Card, CardContent, TextField, Button, NativeSelect, InputLabel } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Consts } from '../../utils/consts';
import * as DataUtils from '../../stores/DataUtils';
import { parseHashLocation } from '../../App';

// -----------
// Defines
// -----------

enum ErrorType {
    ok = 'ok',
    error = 'error',
    undefined = 'undefined'
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'fixed',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            backgroundColor: '#EAEAEA',
            overflow: 'auto'
            // border: '1px solid black',
        },
        container2: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: 600,
            width: '90%',
            maxWidth: '400px',
            // border: '1px solid black',
        },
        card: {
            padding: '0 8px'
        },
        logo: {
            flex: '1 1 20%',
            paddingTop: 24,
            paddingLeft: 24,
            // border: '1px solid black',
        },
        logoImg: {
            padding: '0 0 0 0',
            margin: '0 0 0 0',
            maxHeight: 100,
            maxWidth: 200,
            // border: '1px solid black',
        },
        header: {
            marginTop: 16,
            paddingLeft: 24,
            paddingRight: 24,
            fontSize: '24px',
            // border: '1px solid green',            
        },
        div: {
            // border: '1px solid red',
        },
        form: {
            padding: '0 0 0 0',
            // border: '1px solid black',
        },
        inner: {
            width: '200',
            display: 'flex',
            flexDirection: 'column',
            // border: '1px solid black',
        },
        textField: {
            marginLeft: 0,//theme.spacing(1),
            marginRight: 0,//theme.spacing(1),
            width: '100%',
        },
        linkField: {
            flex: '1',
            marginBottom: '0.5em',
            //marginLeft: 0,//theme.spacing(1),
            //marginRight: 0,//theme.spacing(1),
            color: 'blue'
        },
        error: {
            fontSize: '12px',
            color: 'red',
            marginLeft: 0
        },
        success: {
            fontSize: '12px',
            color: 'green',
            marginLeft: 0
        },
        divButton: {
            padding: '8 0',
            width: "100%",
            position: 'relative',
            marginBottom: '1em'
        },
        button: {
            width: '100%',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -20,
        }
    }),
);

const RegisterPage: React.FC = () => {
    const classes = useStyles();
    //
    const { history } = useReactRouter();
    const hashLocation = parseHashLocation(history.location.search);
    //
    const defaultState = {
        domain: '',
        name: '',
        login: '',
        email: '',
        isSuccess: false
    };
    const [state, setState] = useState(defaultState);
    const [error, setError] = useState({ text: "", type: ErrorType.undefined });
    const [registerDomains, setRegisterDomains] = useState([]);
    //
    useEffect(() => {
        DataUtils.getRegisterDomains().then((regDomains) => {
            if (registerDomains !== regDomains && regDomains.length !== 0) {
                setRegisterDomains(regDomains);
                setState({ ...state, domain: regDomains[0] });
            }
        });
    }, []);

    const logo = "/rest/v1/public/resources/logo";

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.container2}>
                    <Card className={classes.card}>
                        <div className={classes.logo}><img src={logo} alt="l" className={classes.logoImg} /></div>
                        <div className={classes.header}>{Consts.pages.registerPage.header}</div>
                        <div className={classes.div}>
                            <form className={classes.form} onSubmit={(e) => submitHandler(e, state, hashLocation, setState, setError)}>
                                <CardContent className={classes.inner} >
                                    <InputLabel shrink={true} htmlFor="domainSelect">{Consts.pages.registerPage.domain}</InputLabel>
                                    <NativeSelect
                                        id="domainSelect"
                                        onChange={(e) => setState({ ...state, domain: e.target.value })}
                                        value={state.domain}
                                    >
                                        {
                                            registerDomains.map((domain: string) => (
                                                <option key={domain} value={domain}> {domain} </option>
                                            ))
                                        }
                                    </NativeSelect>
                                    <TextField
                                        required
                                        id="name"
                                        label={Consts.pages.registerPage.name}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={(e) => setState({ ...state, name: e.target.value })}
                                    />
                                    <TextField
                                        required
                                        id="login"
                                        label={Consts.pages.registerPage.login}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={(e) => setState({ ...state, login: e.target.value })}
                                    />
                                    <TextField
                                        required
                                        id="email"
                                        label="Email"
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={(e) => setState({ ...state, email: e.target.value })}
                                    />
                                    <div style={{ padding: '9px 0' }}>
                                        <p style={{ margin: '0' }} className={error.type === ErrorType.ok ? classes.success : classes.error}>{error.text}</p>
                                    </div>
                                    <div className={classes.divButton}>
                                        <Button variant="contained" disabled={state.login === '' || state.email === '' || error.type === ErrorType.ok || state.isSuccess || registerDomains.length === 0} className={classes.button} type="submit">{Consts.pages.registerPage.next}</Button>
                                    </div>
                                </CardContent>
                            </form>
                        </div>
                    </Card>
                </div>
            </div>
        </React.Fragment>)
};

// ---
async function submitHandler(e: any, state: any, hashLocation: any, setState: Function, setError: Function) {
    e.preventDefault();
    let body: any = {
        domain: state.domain,
        login: state.login,
        email: state.email,
        name: state.name
    };
    //
    if (hashLocation.meetreferrer) {
        body.state = encodeURIComponent(hashLocation.meetreferrer);
    };
    //
    const reqOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(body)
    };
    const resp: Response = await fetch('/rest/v1/iam/self_register_requests', reqOptions);
    let respBody: any = {};
    try {
        respBody = await resp.json();
    } catch {
        respBody['error_message'] = resp.statusText;
    };
    if (resp && resp.status >= 200 && resp.status <= 299) {
        const successText = DataUtils.getSuccessTextByPage('register', respBody.result_msg);
        setState({ ...state, isSuccess: true });
        setError({ text: successText, type: ErrorType.ok });
    } else {
        const errorMsg = DataUtils.getErrorTextByCode(respBody.error_details, respBody.error_message);
        setError({ text: errorMsg, type: ErrorType.error });
    }
};

export default RegisterPage;