
// ---
// API functions
// ---

// ---
export function language() {
    let lang = navigator.language;
    const splLang = lang.split('-');
    if (splLang.length > 1) {
        lang = splLang[0];
    }
    return lang;
};