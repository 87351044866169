import { Consts } from '../utils/consts';

// ---
export async function getEraDefaultsJson() {
    const reqOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    };
    const resp: Response = await fetch('/rest/v1/public/resources/defaults.json', reqOptions);
    let respBody: any = {};
    try {
        respBody = await resp.json();
    } catch { };
    //
    if (resp && resp.status >= 200 && resp.status <= 299) {
        return respBody;
    };
};

// ---
export async function getRegisterDomains() {
    const reqOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    };
    const resp: Response = await fetch('/rest/v1/public/resources/defaults.json', reqOptions);
    let respBody: any = {};
    let result = [];
    try {
        respBody = await resp.json();
    } catch { };
    //
    if (resp && resp.status >= 200 && resp.status <= 299) {
        const regDomains = respBody.selfRegisterDomains;
        if (regDomains) {
            result = regDomains
        }
    };
    return result
};

// ---
export function getSuccessTextByPage(pageName: string, defaultText: string) {
    if (successResultTexts.hasOwnProperty(pageName)) {
        return successResultTexts[pageName]
    } else {
        return defaultText
    }
};

// ---
const successResultTexts: any = {
    restorePwd: Consts.stores.dataUtils.successResultTexts.restorePwd,
    register: Consts.stores.dataUtils.successResultTexts.register,
    registerSubmit: Consts.stores.dataUtils.successResultTexts.registerSubmit,
    inviteSubmit: Consts.stores.dataUtils.successResultTexts.inviteSubmit,
    resetPwd: Consts.stores.dataUtils.successResultTexts.resetPwd
};

// ---
export function getErrorTextByCode(errorDetails: any, defaultText: string) {
    if (errorCodesTexts.hasOwnProperty(errorDetails.msg_code)) {
        if (errorDetails.hasOwnProperty('field')) {
            const resultText = errorCodesTexts[errorDetails.msg_code][errorDetails.field];
            return resultText ? resultText : defaultText;
        } else {
            const codeText = errorCodesTexts[errorDetails.msg_code];
            if (typeof codeText === 'string' || codeText instanceof String) {
                return codeText
            } else {
                return defaultText
            }
        }
    } else {
        return defaultText
    }
}

// ---
const errorCodesTexts: any = {
    10001: Consts.stores.dataUtils.errorCodesTexts.domainNotFound,
    10002: Consts.stores.dataUtils.errorCodesTexts.userNotFound,
    10006: {
        'opts.email': Consts.stores.dataUtils.errorCodesTexts.invalidData.invalidEmail,
        // 'pwd': Consts.stores.dataUtils.errorCodesTexts.invalidData.invalidPwd
    },
    10012: Consts.stores.dataUtils.errorCodesTexts.accessDenied,
    15304: Consts.stores.dataUtils.errorCodesTexts.emailNotFound
};